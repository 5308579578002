import '../Styles/Inicio.css'
import logo from "../imagenes/Home/INDISA.svg"
import logomet from "../imagenes/Home/Un-Seguro-MetLife.svg"
import { Nav, Navbar, Button } from 'react-bootstrap'

const Inicio = () => {
    return (
      <div id="inicio">
        <div className="card mt-4" id="barraenlace">
          <Navbar expand="lg" className="navbar fixed-top">
            <Navbar.Brand href="#">
              <div id="ilogo" className="animate__animated animate__bounce">
                <a href="https://www.indisa.cl/seguros-y-convenios/"><img src={logo} alt="Indisa"/></a>
                <img style={{ marginLeft: 20, width:'200%'}} src={logomet} alt=""/>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="m-auto my-2 my-lg-0 inav animate__animated animate__bounce"
                navbarScroll
              >
                <Nav.Link href="#sr">NUESTROS PLANES</Nav.Link>
                <Nav.Link href="#cf">¿CÓMO FUNCIONA?</Nav.Link>
                <Nav.Link href="#bc">BENEFICIOS DEL SEGURO</Nav.Link>
                <Nav.Link href="#bcs">CONDICIONES DE NUESTROS SERVICIOS</Nav.Link>
                <Button className="nav-link-button" href="https://portalservicios.metlife.cl/login/indisa?utm_medium=Clinica&utm_source=Boton&utm_campaign=Banner_INDISA" target="_blank">PORTAL DE ASEGURADOS
                </Button>
              </Nav>
              
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    );
}

export default Inicio
