import Inicio from "./components/Inicio"
import JustoNecesitas from "./components/JustoNecesitas"
import SeccionRenata from "./components/SeccionRenata"
import ComoFunciona from "./components/ComoFunciona"
import BeneficiosSeguros from "./components/BeneficiosSeguros"
import Footer from "./components/Footer"
import CondicionesSeguros from "./components/CondicionesSeguros"
import BeneficiosCondicionesServicios from "./components/BeneficiosCondicionesServicios"
import './App.css';

function App() {

  
  return (

    <>
    <Inicio/>
    <JustoNecesitas/>
    <SeccionRenata/>
    <BeneficiosSeguros/>
    <ComoFunciona/>
    <BeneficiosCondicionesServicios/>
    <CondicionesSeguros/>
    <Footer/>
    </>
  
  );
}

export default App;

