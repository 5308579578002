import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import corazon from "../imagenes/ComoFunciona/corazon.svg"
import cama from "../imagenes/ComoFunciona/cama.svg"
import libreta from "../imagenes/ComoFunciona/libreta.svg"
import manocorazon from "../imagenes/ComoFunciona/manocorazon.svg"
import ReactTooltip from 'react-tooltip'

function fechaString(fecha) {
  return new Date(fecha).toLocaleString('es-CL', { timeZone: 'UTC', dateStyle: 'medium'}).split('-').join('/');
}
function ApiCFComplementarioIsapre(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (
<div id="complementarioI" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={corazon} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body ">
                        <p>
                        Te hospitalizan por apendicitis en la Clínica INDISA. 
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div><br/> 
                        <img src={cama} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El total de los gastos de la clínica es de $3.500.000.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div><br/> <br/> <br/> 
                        <img src={libreta} className="img-fluid" id="cflogo" alt=""/>
                      </div>
                      <div className="card-body">
                        <p>
                        Si tu sistema previsional (Isapre o Fonasa) cubre $2.100.000 (60%) quedaría una diferencia por pagar de $1.400.000.
                        </p>                        
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={manocorazon} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El seguro cubrirá un 50%  del copago y te quedaría por pagar $700.000                        
                        </p>
                        <ReactTooltip id='srtooltip999' place="bottom" type="info" effect="solid" >
                          <span>Dependiendo de tu sistema de salud y la clínica donde te hayas atendido. </span>
                        </ReactTooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="container cfparrafo">  
                *En todas las prestaciones  cubiertas previamente por tu Isapre.<br/>  
                Puede aplicar tope máximo según tramo de edad y plan contratado.
                <li className="Uf">
                {!insuranceUF.fetching && insuranceUF.data && ( <span>Valores en pesos son referenciales a la UF del {fechaString(insuranceUF.data.id.publicationDate)}, {`$${Math.round(insuranceUF.data.value).toLocaleString("es-CL")}`}</span>)}
                </li>
                </p>
             
              </div>
  );
}
export default ApiCFComplementarioIsapre;
