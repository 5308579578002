import React, { Component } from 'react';
const apiUrl = `${process.env.REACT_APP_UF_SERVER}`;
interface IProps {
}

interface IState {
  dto: UF;
  isLoading:boolean;
} 
class ApiUF extends Component<IProps, IState> {
  constructor(props:any) {
    super(props);
    this.state = {
      dto: new UF(),
      isLoading: false,
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => this.setState({ dto: data, isLoading: false }));
  }
  render() {
    const { dto, isLoading } = this.state;
    if (isLoading) {
      return <li className="Uf">Cargando valores ...</li>;
    }
    return (
      <li className="Uf">Valores en pesos son referenciales a la UF del {fechaString(dto.id.publicationDate)}, ${dto.value}</li>
    );
  }
}
function fechaString(fecha:number): string {
  return new Date(fecha).toLocaleString('es-CL', { timeZone: 'UTC', dateStyle: 'medium'});
}
export class IdFecha {
  publicationDate: number=0;
  name: string="";
}

export class UF {
  id: IdFecha=new IdFecha();
  symbol: string="";
  value: number=0;
}
export default ApiUF;