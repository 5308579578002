import img1 from '../imagenes/BeneficiosCondiciones/1INDISACancer.svg'
import img2 from '../imagenes/BeneficiosCondiciones/2INDISAInfarto.svg'
import img3 from '../imagenes/BeneficiosCondiciones/3INDISAACV.svg'
import img4 from '../imagenes/BeneficiosCondiciones/4INDISAINSUFICIENCIARENAL.svg'
import img5 from '../imagenes/BeneficiosCondiciones/5INDISATIRAPLEGIA.svg'
import img6 from '../imagenes/BeneficiosCondiciones/6INDISAALZHEIMER.svg'
import img7 from '../imagenes/BeneficiosCondiciones/7INDISAANGIOPLASTIA.svg'
import img8 from '../imagenes/BeneficiosCondiciones/8INDISAAPENDICITIS.svg'
import img9 from '../imagenes/BeneficiosCondiciones/9INDISAEsclerosis.svg'
import img10 from '../imagenes/BeneficiosCondiciones/10INDISAPARÁLISIS.svg'
import img11 from '../imagenes/BeneficiosCondiciones/11INDISAPARKINSON.svg'
import img12 from '../imagenes/BeneficiosCondiciones/12INDISAREVASCULIRIZACION.svg'
import '../Styles/BeneficiosCondiciones.css'

function BeneficiosCondicionesServicios() {
  return (
    <div id="bcs">
      <br/><br/><br/><br/>
      <div className="mt-4 cfdivprin">
        <h1 className="titulos">Beneficios y condiciones de nuestros servicios</h1>
      </div>
    <div className='contenedor'>
     <div className='bccdiv1'>
    <h4 className='bcparrafo'>Si eliges el Seguro Catastrófico, tienes beneficio de deducible CERO en primer diagnóstico de:</h4>
    <span>Con Seguro Oncológico, aplica beneficio cero respecto al primer diagnóstico de cáncer.</span>
</div>
<div className='bccdiv2'>
<div className="bcdivimg">
<div><img src={img1} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Cáncer</b></h6></div>
<div><img src={img2} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Infarto</b> al Miocardio</h6></div>
<div><img src={img3} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>ACV</b> <br/> Accidente vascular cerebral</h6></div>
<div><img src={img4} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Insuficiencia renal</b><br/>crónico</h6></div>
<div><img src={img5} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Cuadriplejia o<br/>Tetraplejia</b></h6></div>
<div><img src={img6} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Alzheimer</b></h6></div>
<div><img src={img7} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Angioplastia,</b><br/>injerto aórtico,<br/>cirugía de las<br/>válvulas cardíacas</h6></div>
</div><br /><br />
<div className="bcdivimg">
<div><img src={img8} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Apendicitis</b></h6></div>
<div><img src={img9} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Esclerosis Múltiple</b></h6></div>
<div><img src={img3} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Neurocirugía</b></h6></div>
<div><img src={img10} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Parálisis</b></h6></div>
<div><img src={img11} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Parkinson</b></h6></div>
<div><img src={img12} className="img-fluid bcimg" alt="" /><h6 className='bcdescrip'><b>Revascularización</b><br/>coronaria (By-pass)</h6></div>
</div>
</div>  
</div>
</div>
  )
}

export default BeneficiosCondicionesServicios