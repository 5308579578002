import '../Styles/Footer.css'
import ApiUF from './ApiUF'

const Footer = () => {
    return (
      <div id="ft">
      <div className="divft">
      <br/>
        <p className="apiuf">
        <ApiUF />
        </p>
        <p>
        Si ya cuentas con el seguro y tienes dudas, ingresa a <a className='ftcorreo' href="https://portalservicios.metlife.cl/login/indisa">https://portalservicios.metlife.cl/login/indisa</a>
        </p>
        <p className="textoft">
            **El riesgo es cubierto por MetLife Chile Seguros de Vida S.A.,y se rige por las condiciones generales de esta póliza incorporadas al depósito de la Comisión Del Mercado Financiero bajo los códigos POL 3 2020 0090, POL 3 2013 0146, POL 3 2022 0183, POL 3 2023 0260, POL 3 2023 0394 CAD 2 2013 0244 y CAD 3 2019 0121.
            La presente información sólo representa un resumen de las coberturas, condiciones y limitaciones del SEGURO SIEMPRE INDISA. El detalle de las condiciones, términos y exclusiones, se encuentra en las Condiciones Generales antes mencionadas y en las Condiciones Particulares de la Póliza. Seguro exclusivo para prestaciones otorgadas en Clínica INDISA.
            La entrega del convenio señalado no constituye coberturas de seguros y es de responsabilidad exclusiva de Clínica INDISA, sin involucrar en forma alguna a MetLife Chile Seguros de VIDA S.A, quien podrá poner término a este convenio en cualquier momento, cuando así lo estime conveniente, cosa de será informado oportunamente los usuarios.
            Los 3 primeros meses de cobertura sin costo de Seguro INDISA son exclusivos para la contratación por internet.
            Oferta exclusiva para personas naturales que residan en Chile y estén afiliados a una ISAPRE o FONASA.
        </p>
      </div>

        {/* <div className="container footer">
          <div className="fdivlogo ">
            <img src={logo} className="flogo" alt="MetLife" />
          </div>
          <div className="fdivenlaces">
            <div className="fdivenlace">
              <div className="fdivp1">
                <p>
                  <a
                    href="https://w3.metlife.cl/privacidad/"
                    target="_blank"
                    rel="noreferrer"
                    className="fenlaces"
                  >
                    Privacidad
                  </a>
                </p>
                <span className="ficono">
                  <i className="bi bi-record-fill"></i>
                </span>
                <p>
                  <a
                    href="https://www.ddachile.cl/Inicio.aspx?M=0F4F1745527C84993BCD7EDC91EA748E5CB53DBA"
                    target="_blank"
                    rel="noreferrer"
                    className="fenlaces"
                  >
                    Defensor del Asegurado
                  </a>
                </p>
                <span className="ficono">
                  <i className="bi bi-record-fill"></i>
                </span>
                <p>
                  <a
                    href="https://w3.metlife.cl/content/dam/metlifecom/cl/pdfs/carta_codigo_atorregulacion_y_compendio_buenas_practicas.pdf"
                    target="_blank"
                    rel="noreferrer"
                    className="fenlaces"
                  >
                    {" "}
                    Consejo de Autorregulación
                  </a>{" "}
                </p>
              </div>
              <div className="fdivp2">
                <span className="ficono">
                  <i className="bi bi-record-fill"></i>
                </span>
                <p>
                  <a
                    href="https://w3.metlife.cl/circular/"
                    target="_blank"
                    rel="noreferrer"
                    className="fenlaces"
                  >
                    {" "}
                    Circular N° 2131
                  </a>
                </p>
                <span className="ficono">
                  <i className="bi bi-record-fill"></i>
                </span>
                <p>
                  <a
                    href="https://w3.metlife.cl/sobre-metlife/quienes-somos/informacion-corporativa/"
                    target="_blank"
                    rel="noreferrer"
                    className="fenlaces"
                  >
                    Información Corporativa
                  </a>
                </p>
                <span className="ficono">
                  <i className="bi bi-record-fill"></i>
                </span>
                <p>
                  <a
                    href="https://www.metlife.cl/publico_tridion/aplicaciones/fiscalia/index.asp"
                    target="_blank"
                    rel="noreferrer"
                    className="fenlaces"
                  >
                    Contacto Ley 20.393
                  </a>
                </p>
              </div>
            </div>
            <div className="fdivinfo">
              <p>Agustinas 640, Santiago, Chile | Compañías MetLife</p>
              <p className="fcorreo">Si ya cuentas con el seguro y tienes dudas, ingresa a <a className='ftcorreo' href="https://portalservicios.metlife.cl/login/indisa">https://portalservicios.metlife.cl/login/indisa</a></p>
              <p className="fcorreo">
                <ApiUF />
              </p>
            </div>
            <div className="fdivenlacesinternos">
              <p>
                {" "}
                <a href="#sr" className="fenlaces">
                  Nuestros planes
                </a>
              </p>
              <span className="ficono">
                <i className="bi bi-record-fill"></i>
              </span>
              <p>
                <a href="#cf" className="fenlaces">
                  ¿Cómo funciona?
                </a>
              </p>
              <span className="ficono">
                <i className="bi bi-record-fill"></i>
              </span>
              <p>
                <a href="#bc" className="fenlaces">
                  Beneficios del seguro
                </a>
              </p>
            </div>
          </div>
        </div> */}

        {/* <div id="ftlegal"  className="fdivtextolegal  ">
          <ul  className="social_footer_ul ">
          <br>
          </br>
          <br>
          </br>
          <p className="text-center cfparrafo">
            <ApiUF/>
            </p>
          <p className="text-center cfparrafo">
            <br>
            </br>
           **El riesgo es cubierto por MetLife Chile Seguros de Vida S.A.,y se rige por las condiciones generales de esta póliza incorporadas al depósito de la Comisión Del Mercado Financiero bajo los códigos POL 3 2019 0074, POL 3 2013 0223 ,POL 320200090  y POL 320130146 , además CAD 2 2013 0244 y CAD 2 2013 0227.
            La presente información sólo representa un resumen de las coberturas, condiciones y limitaciones del SEGURO SIEMPRE INDISA. El detalle de las condiciones, términos y exclusiones, se encuentra en las Condiciones Generales antes mencionadas y en las Condiciones Particulares de la Póliza. Seguro exclusivo para prestaciones otorgadas en Clínica INDISA.
            La entrega del convenio señalado no constituye coberturas de seguros y es de responsabilidad exclusiva de Clínica INDISA, sin involucrar en forma alguna a MetLife Chile Seguros de VIDA S.A, quien podrá poner término a este convenio en cualquier momento, cuando así lo estime conveniente, cosa de será informado oportunamente los usuarios.
            Los 3 primeros meses de cobertura sin costo de Seguro Siempre INDISA son exclusivos para la contratación por internet.
            Oferta exclusiva para personas naturales que residan en Chile y estén afiliados a una ISAPRE o FONASA.

          </p>
          </ul>
        </div> */}
        <input id="version" name="version" type="hidden" value={"V" + process.env.REACT_APP_VERSION} />
      </div>
    );
  }
export default Footer
