import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import persona from "../imagenes/ComoFunciona/persona.svg"
import cama from "../imagenes/ComoFunciona/cama.svg"
import libreta from "../imagenes/ComoFunciona/libreta.svg"
import manocorazon from "../imagenes/ComoFunciona/manocorazon.svg"

function fechaString(fecha) {
  return new Date(fecha).toLocaleString('es-CL', { timeZone: 'UTC', dateStyle: 'medium'}).split('-').join('/');
}
function ApiCFCatastroficoFonasa(props) {
  const dispatch = useDispatch();
  const insuranceUF = useSelector((state) => state.InsuranceUF);

  useEffect(() => {
    dispatch({
      type: "INSURANCE_UF_REQUEST",
    });
  }, [dispatch]);

  return (
    <div id="catastroficoF" className="container tab-pane active">
                <br />
                <div id="cfcol" className="row row-cols-2 row-cols-md-4 mb-4 text-center">
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={persona} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Sientes un dolor abdominal, vas a urgencias de la clínica INDISA en donde te diagnostican pancreatitis aguda.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p><br />
                      <div>
                        <img src={cama} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El total de la cuenta en la Clínica es de 20.500.000 ( honorarios médicos, insumos, exámenes y post operatorio).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={libreta} className="img-fluid" id="cflogo" alt="" />
                      </div>
                      <div className="card-body">
                      <div>
                      Si tu sistema previsional (Isapre o Fonasa) cubre $1.000.000 quedaría una diferencia por pagar de $19.500.000.<br/><br/>
                          Al aplicar el seguro, tendrías que pagar el deducible según tu tramo de edad, por ejemplo UF 20 ($710.000 apróx) y después tendrás cubierto hasta el 100% del monto restante $18.790.000.                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div><br/>
                        <img src={manocorazon} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Además te seguiremos cubriendo durante 3 años por gastos asociados a este diagnóstico.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="container cfparrafo">
              *En todas las prestaciones  cubiertas previamente por tu ISAPRE.<br/>  
              Puede aplicar tope máximo según tramo de edad y plan contratado.
              <li className="Uf">
                {!insuranceUF.fetching && insuranceUF.data && ( <span>Valores en pesos son referenciales a la UF del {fechaString(insuranceUF.data.id.publicationDate)}, {`$${Math.round(insuranceUF.data.value).toLocaleString("es-CL")}`}</span>)}
              </li>
              </p>
              </div>        
  );
}
export default ApiCFCatastroficoFonasa;
