import React from 'react'
import "../Styles/SeccionRenata.css";
// import Aos from "aos"
// import "aos/dist/aos.css"

function CondicionesSeguros() {
  // React.useEffect(() => {
  //   Aos.init({duration: 2000});
  //     }, []);
  return (
    // <section data-aos="fade-up">
    <section>
          <div className="container">
            <ul className="nav nav-tabs" role="tablist" style={{"borderBottom" : "none", marginLeft:"0.7rem"}}>
              <li className="nav-item card">
                <a className="nav-link active srtitulos" data-toggle="tab" href="#ccatastrofico">
                  <div>
                    <h6 className="srnombres">Catastrófico</h6>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a className="nav-link srtitulos" data-toggle="tab" href="#cisapre">
                  <div>
                    <h6 className="srnombres">Ambulatorio</h6>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a className="nav-link srtitulos" data-toggle="tab" href="#chospitalario">
                  <div>
                    <h6 className="srnombres">Hospitalario</h6>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a className="nav-link srtitulos" data-toggle="tab" href="#chospcirugia">
                  <div>
                    <h6 className="srnombres">Hospitalario + Cirugías</h6>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a className="nav-link srtitulos" data-toggle="tab" href="#curgencias">
                  <div>
                    <h6 className="srnombres">Urgencias</h6>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a className="nav-link srtitulos" data-toggle="tab" href="#caccidentes">
                  <div>
                    <h6 className="srnombres">Convenio de accidentes</h6>
                  </div>
                </a>
              </li>
              <li className="nav-item card">
                <a className="nav-link srtitulos" data-toggle="tab" href="#concologico">
                  <div>
                    <h6 className="srnombres">Oncológico</h6>
                  </div>
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div id="ccatastrofico" className="container tab-pane active">
                <div className="bcparrafo col card">
                  <ul className="bcparrafo3" style={{lineHeight:"2rem"}}>
                  <li>Debes tener una afiliación vigente de ISAPRE o FONASA.</li>
                  <li>Puedes contratar seguro si eres mayor de 18 años y menor de 74 años.</li>
                  <li>Puedes incorporar cónyuge, conviviente civil o pareja de ser mayor de 18 y menor de 74 años.</li>
                  <li>Puedes incorporar carga a tus hijos desde los 14 días de vida, hasta ser menor de 24 años.</li>
                  <li>No cubre preexistencias.</li>
                  <li>Debes complementar una declaración personal de salud para poder avanzar en la contratación ( pueden aplicar exclusiones).</li>
                  <li>El capital disminuye y prima aumenta por tramo de edad. Sólo catastrófico.</li>
                  </ul>
                </div>
              </div>
              <div id="cisapre" className="container tab-pane fade">
                <div
                  className="bcparrafo col card"
                >
                  <ul className="bcparrafo3" style={{lineHeight:"2rem"}}>
                  <li>Debes tener una afiliación vigente de Isapre o Fonasa.</li>
                  <li>Puedes contratar seguro desde 18 años hasta 69 años.</li>
                  <li>Puedes incorporar cónyuge de ser mayor de 18 y menor de 69 años.</li>
                  <li>Puedes incorporar carga a tus hijos des los 14 días de vida, hasta ser menor de 22 años.</li>
                  <li>Se debe completar una declaración personal de salud.</li>
                  <li>No cubre preexistencias.</li>
                  </ul>
                </div>
              </div>
              <div id="chospitalario" className="container tab-pane fade">
                <div className="bcparrafo col card">
                  <ul className="bcparrafo3" style={{lineHeight:"2rem"}}>
                  <li>Debes tener una afiliación vigente de Isapre o Fonasa.</li>
                  <li>Puedes contratar seguro desde 18 años hasta 69 años.</li>
                  <li>Puedes incorporar cónyuge de ser mayor de 18 y menor de 69 años.</li>
                  <li>Puedes incorporar carga a tus hijos des los 14 días de vida, hasta ser menor de 22 años.</li>
                  <li>Se debe completar una declaración personal de salud.</li>
                  <li>No cubre preexistencias.</li>
                  <li>Debes complementar una declaración personal de salud para poder avanzar en la contratación (pueden aplicar exclusiones).</li>
                  </ul>
                </div>
              </div>
              <div id="chospcirugia" className="container tab-pane fade">
                <div className="bcparrafo col card">
                  <ul className="bcparrafo3" style={{lineHeight:"2rem"}}>
                    <li>Debes tener una afiliación vigente de Isapre o Fonasa.</li>
                    <li>Puedes contratar seguro desde 18 años hasta 69 años.</li>
                    <li>Puedes incorporar cónyuge de ser mayor de 18 y menor de 69 años.</li>
                    <li>Puedes incorporar carga a tus hijos des los 14 días de vida, hasta ser menor de 22 años.</li>
                    <li>Se debe completar una declaración personal de salud.</li>
                    <li>No cubre preexistencias.</li>
                    <li>Debes complementar una declaración personal de salud para poder avanzar en la contratación (pueden aplicar exclusiones).</li>
                  </ul>
                </div>
              </div>
              <div id="curgencias" className="container tab-pane fade">
                <div className="bcparrafo col card">
                  <ul className="bcparrafo3" style={{lineHeight:"2rem"}}>
                    <li>Debes tener una afiliación vigente de Isapre o Fonasa.</li>
                    <li>Puedes contratar seguro desde 18 años hasta 59 años.</li>
                    <li>Puedes incorporar cónyuge de ser mayor de 18 y menor de 59 años.</li>
                    <li>Puedes incorporar carga a tus hijos des los 14 días de vida, hasta ser menor de 22 años.</li>
                    <li>Se debe completar una declaración personal de salud.</li>
                    <li>No cubre preexistencias.</li>
                  </ul>
                </div>
              </div>
              <div id="caccidentes" className="container tab-pane fade">
                <div
                  className="bcparrafo col card"
                >
                  <ul className="bcparrafo3" style={{lineHeight:"2rem"}}>
                  <li>Debes ser afiliado de ISAPRE o FONASA (B, C y D).</li>
                  <li>Debes contratarlo junto al seguro catastrófico siempre INDISA.</li>
                  <li>Puedes contratar seguro desde 14 días  hasta los 64 años.</li>
                  <li>Puedes incorporar cónyuge de ser mayor de  18 y menor de 64  años.</li>
                  <li>Puedes incorporar carga a tus hijos des los 14 días de vida, hasta ser menor de 24 años.</li>
                  <li>No constituye cobertura de seguros, es responsabilidad de Clínica INDISA.</li>
                  </ul>
                </div>
              </div>
              <div id="concologico" className="container tab-pane fade">
                <div
                  className="bcparrafo col card"
                >
                  <ul className="bcparrafo3" style={{lineHeight:"2rem"}}>
                  <li>Debes tener una afiliación vigente de ISAPRE o FONASA.</li>
                  <li>Puedes contratar seguro si eres mayor de 18 años y menor de 70 años.</li>
                  <li>Puedes incorporar cónyuge de ser mayor de  18 y menor de 70 años.</li>
                  <li>Puedes incorporar carga a tus hijos desde los 14 días de vida, hasta ser menor de 23 años.</li>
                  <li>No cubre preexistencias. </li>
                  <li>Debes complementar una declaración personal de salid para poder avanzar en la contratación ( pueden aplicar exclusiones).</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
  )
}

export default CondicionesSeguros