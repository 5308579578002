import bicicleta from "../imagenes/ComoFunciona/bicicleta.svg"
import cama from "../imagenes/ComoFunciona/cama.svg"
import libreta from "../imagenes/ComoFunciona/libreta.svg"
import manocorazon from "../imagenes/ComoFunciona/manocorazon.svg"

function APICFAccidentes(props) {
  return (
<div id="convenio" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={bicicleta} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Tienes un accidente en bicicleta, al llegar a Clínica INDISA, te atiendes en urgencias y te operan la rodilla.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div><br/>
                        <img src={cama} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El total de la cuenta en la Clínica es de $12.000.000 ( honorarios médicos, insumos, exámenes y gastos hospitalarios).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={libreta} className="img-fluid" id="cflogo" alt="" />
                      </div>
                      <div className="card-body">
                        Si tu ISAPRE cubre  $6.000.000 (50%) queda un saldo de $6.000.000 por pagar.<br/>
                        Al activarse el convenio**, la diferencia queda pagada.
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div><br/>
                        <img src={manocorazon} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Además cubrirá exámenes, procedimientos y controles posteriores asociados al accidente durante un año.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="container cfparrafo">  
              * No es cobertura.<br/>
              ** El convenio tiene un tope de $24.000.000 de capital anual.<br/>
                Puede aplicar tope máximo según tramo de edad y plan contratado.
              </p>  
              </div>
  );
}
export default APICFAccidentes;