import { combineReducers } from "redux";
import InsuranceUF from "./InsuranceUF";
import InsuranceTypes from "./InsuranceTypes";
import InsuranceByTypeInsurance from "./InsuranceByTypeInsurance";
import InsuranceComparator from "./InsuranceComparator";
export default combineReducers({
  InsuranceUF,
  InsuranceTypes,
  InsuranceByTypeInsurance,
  InsuranceComparator,
});
