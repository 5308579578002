import '../Styles/JustoNecesitas.css'

const JustoNecesitas = () => {
    return (
      <>
      <div id="jn">        
          <h2 className="jntitulos">Conoce tus Seguros INDISA tanto para ISAPRE como FONASA</h2>
      </div>
      <div className='justoBox'>
{/*       <ul
              className="justoTab nav nav-tabs"
              role="tablist"
              style={{ marginLeft: "1rem" }}
            >
              <li className="funcionaTitulo nav-item card">
                <div>
                    <h6 className="cftitulos">Catastrófico</h6>
                  </div>
              </li>
              <li className="funcionaTitulo nav-item card">
                  <div>
                    <h6 className="cftitulos">Complementarios</h6>
                  </div>
              </li>
              <li className="funcionaTitulo nav-item card">
                  <div>
                    <h6 className="cftitulos">Oncológico</h6>
                  </div>
              </li>
            </ul> */}
        </div>
      </>
    );
}
export default JustoNecesitas