import "../Styles/Planes.css";
import medalla from "../imagenes/SeccionRenata/medalla.svg";
import React from "react";
import { IconCheckbox } from "react-icon-checkbox";
import { useDispatch } from "react-redux";
import { Display } from "react-bootstrap-icons";

const MAX_SELECTABLE = 3;
const DEFAULT_CARDS_TO_SHOW = 3;

const Planes = ({ data, click, index, watchAll, selectedList, numPeople, maxAge, amounts}) => {
  const dispatch = useDispatch();
  const {
    idSeguro,
    nombreSeguro,
    cobertura,
    valorSeguro,
    valorPrima,
    url,
    clinica1,
  } = data;

  const [value, setValue] = React.useState(false);

  const onChangeValue = () => {
    if (selectedList.length <= MAX_SELECTABLE) {
      click(idSeguro, !value);
      setValue(!value);
    } else {
      if (value) {
        click(idSeguro, !value);
        setValue(!value);
      }
    }
  };

  if (index > DEFAULT_CARDS_TO_SHOW && !watchAll) {
    return null;
  }
  return (
    <div className="row mb-3 text-center" key={idSeguro}>
      <div className="col plcol">
        <div className="card mb-4 rounded-bottom-left-1 rounded-bottom-right-1 rounded-top-right-1 rounded-top-left-1 shadow-sm h-100">
          <div className={"card-header text-white rounded-top-left-1 rounded-top-right-1 " + clinica1}>
            <div className="nombreSeguro">{nombreSeguro}</div>
          </div>
          <div className="card-body">
          <br/>
            <h1 className="card-title pricing-card-title">
              <strong>{`$${valorSeguro.toLocaleString("de-DE")}`}</strong>
              <small className="text-muted fw-light pluf">{` UF ${valorPrima}/mes`}</small>
            </h1>
            <div className="plparrafo mb-5" 
                dangerouslySetInnerHTML={{
                  __html: cobertura
                }}></div>
            <button
              id={"detalle" + {idSeguro}}
              type="button"
              className="w-60 btn btn-lg btn-primary mb-3 plboton"
              style={{ color: "white", background:"#0090DA", border:"none" }}
              onClick={() => {
                dispatch({
                  type: "INSURANCE_COMPARATOR_REQUEST",
                  params: {
                    numPersonas: numPeople,
                    edadMaxima: maxAge,
                    vmMaximo: amounts[1],
                    strSeguros: idSeguro,
                  },
                });
              }}
            >
              Ver detalle
            </button><br/>
            <a target="_blank" rel="noreferrer"
              href={url}
              className="w-60 btn btn-lg btn-primary mb-3 plboton"
              style={{ color: "white",border:"none" }}>Contrata</a>
              {/*<p className="detallepie"><i>Haciendo click en "Me interesa" podrás solicitar un ejecutivo</i></p>*/}
            <div className="row" style={{ height: 40, alignItems: "flex-end" }}>
              <div>
                <IconCheckbox
                  checked={value}
                  checkedIcon={
                    <div className="card rounded-3 shadow-sm psdivcomparar">
                    <p className=" container pscomparar">Comparar<i
                      className="bi bi-plus-lg plicono plicono"
                      style={{ color: "#a4ce4e" }}
                    ></i></p>
                  </div>
                  }
                  uncheckedIcon={<div className="card rounded-3 shadow-sm pdivcomparar">
                  <p className="container pcomparar">Comparar<i 
                  className="bi bi-plus-lg plicono plicono">
                  </i></p>  
                  </div>}
                  onClick={onChangeValue}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Planes;
