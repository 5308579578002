import cama from "../imagenes/ComoFunciona/cama.svg"
import libreta from "../imagenes/ComoFunciona/libreta.svg"
import manocorazon from "../imagenes/ComoFunciona/manocorazon.svg"
import lazo from "../imagenes/ComoFunciona/lazo.svg"
import ReactTooltip from 'react-tooltip'

function APICFOncologico(props) {
  return (
    <div id="oncologico" className="container tab-pane fade">
                <br />
                <div
                  className="row row-cols-2 row-cols-md-4 mb-4 text-center"
                  id="cfcol"
                >
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">1</p>
                      <div>
                        <img src={lazo} className="img-fluid" id="cfimg3" alt="" />
                      </div>
                      <div className="card-body ">
                        <p>
                        Estás viendo un especialista en Clínica INDISA, el que te entrega un diagnóstico de cáncer y comienzas tu tratamiento.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">2</p>
                      <div>
                        <img src={cama} className="img-fluid" id="cfimg2" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        El total de las prestaciones es de $90.000.000 ( honorarios médicos, insumos, exámenes y tratamiento).
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">3</p>
                      <div>
                        <img src={libreta} className="img-fluid" id="cflogo" alt=""/>
                      </div>
                      <div className="card-body">
                        Si tu ISAPRE cubre $54.000.000 (60%) queda una diferencia de $36.000.000 por pagar.<br/><br/> 
                        Al aplicar el seguro, el cual tiene deducible cero*, tendrás cubierto hasta el 100%** del monto restante ($36.000.000).
                      </div>
                    </div>
                  </div>
                  <div className="col cfcolum">
                    <div className="card mb-4 rounded-3 shadow-sm h-100 cfcard">
                    <p className="cfnumeracion">4</p>
                      <div>
                        <img src={manocorazon} className="img-fluid" id="cfimg1" alt="" />
                      </div>
                      <div className="card-body">
                        <p>
                        Te seguimos cubriendo 3 años por gastos asociados desde la detección. 
                        Si es el primer diagnóstico de cáncer, además  tienes un monto de libre disposición de 50 UF para ti.
                        </p>
                        <ReactTooltip id='srtooltip999' place="bottom" type="info" effect="solid" >
                          <span>Dependiendo de tu sistema de salud y la clínica donde te hayas atendido. </span>
                        </ReactTooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="container cfparrafo">  
              *A partir de los 60 años el deducible aumenta a UF 60 por evento.<br/>
              **En todas las prestaciones  cubiertas previamente  por tu sistema de salud.</p>
              </div>
  );
}
export default APICFOncologico;
