import Table from "react-bootstrap/Table";
import "../Styles/Planes.css";
import ReactTooltip from 'react-tooltip'
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'
import React, {useEffect, useState} from 'react';
import { Button } from "react-bootstrap";

const StyleModal={
  position:"absolute",
  top:"50p%",
  left:"50%",
  transform:"translate(-50%,50%)"
}



function Comparator({ data }) {
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const [estadoModal1,cambiarestadoModal1] = useState(false);
  const [estadoModal2,cambiarestadoModal2] = useState(false);
  const [estadoModal3,cambiarestadoModal3] = useState(false);
  return (
    <Table striped bordered responsive>
      <thead >
        <tr className="ctr">
          <th></th>
          {data.map(({ nombreSeguro}) => {
            return (
              <td>
                <center>
                  <div className="cnombreseguro">
                    <h5><strong>{nombreSeguro}</strong></h5>
                  </div>
                </center>
              </td>

            );
          })}
        </tr>
        <tr>
          <th><div className="compasistencia"><h4 className="cpasistencia">Asistencia</h4></div></th>
          {data.map(({ valorSeguro, valorPrima, url}) => {
            return (
              <td >
                <center >
                  <h2 className="cvalorseguro"><strong>{`$${valorSeguro.toLocaleString("de-DE")}`}</strong></h2>
                  <h6 className="cvalorprima">{`UF ${valorPrima}/mes`}</h6>
                  <a
                    target="_blank" rel="noreferrer"
                    href={url}
                    class="w-60 btn btn-sm btn-primary mb-3"
                    style={{fontWeight:"bold", color:"white"}}
                  >
                    Contratar Plan
                  </a>
                  <br></br>
                </center>
              </td>

            );
          })}
        </tr>
      </thead>
      <tbody>
        {data[0].comparacion.map(({caracteristica}, index) => {
          function ToolTipMaternidad(props) {
            const Etiqueta = props.Etiqueta;
            if (Etiqueta === "Maternidad") {return <i class="bi bi-info-circle icoinfo" data-html="true" data-tip="La fecha probable de inicio<br /> del embarazo debe ser<br /> posterior a la fecha de inicio<br /> de la vigencia de esta<br /> cobertura para el asegurado<br /> que se aplica." data-for={Etiqueta}></i>;}
            return "";
          }
          return (
            <tr>
            <td><div style={{textAlign:"center"}}>
              <strong>{caracteristica}</strong>
              <ToolTipMaternidad Etiqueta={caracteristica} />
            </div></td>
              {data.map(({ comparacion }) => {

                function CheckedCompare(props) {
                        const IsCheck = props.IsCheck;
                        const Check = props.Check;
                        if (IsCheck && Check) {  return <div className="cdivicon"><i size={3} className="bi bi-check-circle coicono" style={{ color: "#1766DC"}}></i></div>; }
                        if (IsCheck && !Check) {  return <i className="bi bi-check-circle coicono"></i>; }
                        return "";
                      }

                function ShowInformacion(props) {
                        const Caracteristica = props.Caracteristica;
                        const Informacion = props.Informacion;
                        if (Caracteristica === "c1") {  return <p>Tope en clínicas con <span className="cpclinicas"  onClick={()=>cambiarestadoModal1(!estadoModal1)}><u>Cobertura Preferente</u>, </span>{Informacion}</p> ; }
                        if (Caracteristica === "c2") {  return <p>Tope en clínicas con <span className="cpclinicas"  onClick={()=>cambiarestadoModal2(!estadoModal2)}><u>Cobertura Básica</u>, </span>{Informacion}</p> ; }
                        if (Caracteristica === "c3") {  return <p>Tope en clínicas con <span className="cpclinicas"  onClick={()=>cambiarestadoModal3(!estadoModal3)}><u>Cobertura Estándar</u>, </span>{Informacion}</p> ; }
                        return <div dangerouslySetInnerHTML={{__html: Informacion}} />;
                      }

                function EmptyCompare(props) {
                  const Informacion = props.Informacion;
                  const IsCheck = props.IsCheck;
                  if (!IsCheck && ((Informacion.trim()).length === 0)  ){  return <i className="bi bi bi-x-circle coicono"></i>; }
                  return "";
                }


                return (
                  <td style={{textAlign:"center"}}>
                    <ReactTooltip id={caracteristica} type="info" effect="solid">
                      {comparacion[index].comentario}                    
                    </ReactTooltip>
                    <Modal
                          isOpen={estadoModal1}
                          style={StyleModal}
                          >
                          <ModalHeader style={{justifyContent:"right"}}><i data-tip="React-tooltip" data-for="srtooltipComparator" style={{"fontSize" : "30px"}} class="bi bi-x-lg cpbtncerrar" onClick={()=>cambiarestadoModal1(false)}>
                            <ReactTooltip id="srtooltipComparator" place="right" type="info" effect="solid">
                              Volver
                            </ReactTooltip>
                            </i></ModalHeader>
                          <ModalBody>
                          <center>
                          <h1>Clínicas cobertura Preferente</h1>
                          <h5>Integramédica</h5>
                          <h5>Red Salud</h5>
                          <h5>Dávila</h5>
                          </center>
                          </ModalBody>
                          <center><Button className="mb-3 btndescargar" target="_blank" href="/files/clinica_cobertura_preferente.pdf" download >Descargar el listado completo</Button>
                          
                          </center>
                    </Modal>
                    <Modal
                          isOpen={estadoModal2}
                          style={StyleModal}
                          >
                          <ModalHeader style={{justifyContent:"right"}}><i data-tip="React-tooltip" data-for="srtooltipComparator" style={{"fontSize" : "30px"}} class="bi bi-x-lg cpbtncerrar" onClick={()=>cambiarestadoModal2(false)}>
                            <ReactTooltip id="srtooltipComparator" place="right" type="info" effect="solid">
                              Volver
                            </ReactTooltip>
                            </i></ModalHeader>
                          <ModalBody>
                          <center>
                          <h1>Clínicas cobertura Básica</h1>
                          <h5>1. Clínica Alemana de Santiago</h5>
                          <h5>2. Clínica Las Condes</h5>
                          <h5>3. Clínica San Carlos de Apoquindo Red Salud UC Christus</h5>
                          <h5>4. Hospital Clínico Universidad de los Andes</h5>
                          <h5>5. Centro de la Visión Clínica Las Condes</h5>
                          </center>
                          </ModalBody>
                    </Modal>
                    <Modal
                          isOpen={estadoModal3}
                          style={StyleModal}
                          >
                          <ModalHeader style={{justifyContent:"right"}}><i data-tip="React-tooltip" data-for="srtooltipComparator" style={{"fontSize" : "30px"}} class="bi bi-x-lg cpbtncerrar" onClick={()=>cambiarestadoModal3(false)}>
                            <ReactTooltip id="srtooltipComparator" place="right" type="info" effect="solid">
                              Volver
                            </ReactTooltip>
                            </i></ModalHeader>
                          <ModalBody>
                          <center>
                          <h1>Clínicas cobertura Estándar</h1>
                          <h5>Santa María</h5>
                          <h5>Indisa</h5>
                          <h5>Clínica Alemana de Valdivia</h5>
                          <br></br>
                          </center>
                          </ModalBody>
                          <ModalFooter style={{background: "#cce9f7"}} >
                            <center>
                            <h4>Y cualquier otra que no aparezca como Preferente ni Básica.</h4>
                            </center>
                          </ModalFooter>                                                                             
                    </Modal>
                    <CheckedCompare Caracteristica={comparacion[index].caracteristica} IsCheck={comparacion[index].isCheck} Check={comparacion[index].check}/>
                    <ShowInformacion Caracteristica={comparacion[index].comentario} Informacion={comparacion[index].informacion}/>
                    <EmptyCompare Informacion={comparacion[index].informacion} IsCheck={comparacion[index].isCheck} Check={comparacion[index].check}/>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );

}
export default Comparator;
