import '../Styles/BeneficiosCondiciones.css'
import React from 'react'

const BeneficiosCondiciones = () => {
    return (
      <div id="bc">
        <div className="mt-4 cfdivprin">
        <br />          
        <h1 className="titulos">Beneficios del seguro</h1>
        <br />
        <div className="bccard">
        <div  className="container">
          <section id="bcsection1">
            <div className="row row-cols-2 row-cols-md-2 mb-4 container bcconten">
              <div className="container">
              <br />
              <br />
                <h4>¡Menos trámites! Las cuentas hospitalarias 
                son tramitadas internamente entre la Clínica INDISA y MetLife. Tu sólo pagas una vez aplicados todos los descuentos.</h4>
              <br />
              <br />
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>
</div>      
    );
}

export default BeneficiosCondiciones
